.sonuModal__container {
  width: 370px;
  height: auto;
  background: #f2f2f2;
}

.sonu-input__main-container {
  display: flex;
  flex-direction: inherit;
}

.sonu-modal__header-text {
  font-size: 16px;
  background-color: #f2f2f2;
}

.sonu__input {
  background-color: white!important;
  border-radius: 4px;
}

.sonu-input__disabled {
  background-color: #e3e3e3!important;
  color: gray!important
}

.sonu-input__container {
  display: flex;
  margin: 6px 18px;
}

.sonu-input__container label {
  width: 160px;
}

.SONU-Modal__header {
  color: #51B2AD;
  font-size: 18px;
  place-content: center;
  background-color: #f2f2f2 !important;
  min-height: 0px;
  height: 40px;
}

.SONU-Modal__header-closeBtn {
  color: white;
  background-color: #51B2AD;
  width: 20px !important;
  height: 20px !important;
  top: -12px;
  right: -60px;
  margin: 0!important;
}

.sonu-input-notes__container {
  margin: 4px 30px 8px 20px;
}

.sonu-buttons__container {
  display: flex;
  flex-direction: row!important;
  justify-content: center;
  margin: 16px;
}

.sonu-button {
  margin: 2px 30px;
  padding: 2px 22px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  color: #51B2AD;
  border: 1px solid #51B2AD;
}

.sonu-button-confirm {
  background-color: #51B2AD;
  color: white;
}

.buttonDisabled {
  cursor: not-allowed;
  background-color: lightgray;
}

.sonu__input-container {
  width: 316px;
  border-radius: 8px;
  display: flex;
  margin: 8px 0 8px 22px;
}

.sonu-type__text {
  margin: 0 12px;
  color: black;
  min-width: 70px;
}

.sonu-error-container {
  margin: -6px 10px 10px 128px;
  color: red;
}

.order-history__container {
  min-width: 560px;
  max-width: 700px;
  min-height: 300px;
  height: auto;
  background: #f2f2f2;
  justify-content: flex-start !important;
}

.order-history-modal__header {
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  min-height: 54px;
}

.order-history-modal__table {
  display: block;
  overflow-y: auto!important;
  max-height: 450px!important;
}

.order-history-modal__table{
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.order-history-modal__table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.order-history-modal__table .history-modal__th {
  border: 1px solid #dddddd;
  background-color: #f0f0f0;
  text-align: left;
  padding: 4px;
  text-align: center;
}

.order-history-modal__table .history-modal__td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  text-align: center!important;
}

.history-modal__tr > td {
  min-width: 120px;
  max-width: 200px;
}

.order-history-modal__table .history-modal__td:nth-child(1) {
  min-width: 100px;
}

.order-history-modal__table .history-modal__td:nth-child(2) {
  min-width: 120px;
}

.order-history-modal__table .history-modal__td:nth-child(3) {
  text-align: left;
}

.order-history-modal__header-text {
  margin: 8px!important;
}

.order-history__header-closeBtn {
  position: absolute;
  top: 10px;
  right: 12px;
  color: white;
  background-color: #51B2AD;
  width: 20px !important;
  height: 20px !important;
  margin: 0!important;
}

.loading-container {
  position: absolute;
  top: 40%;
  left: 47%;
}

.third-tr {
  min-width: 90px;
}

.history-modal_no-data {
  font-size: 16px;
  align-items: center;
  margin-top: 54px;
}