.call-checking__carrierName {
  color: white;
}

.call-checking__carrierName:hover {
  color: white;
}

.green__BG {
  background-color: #D9EBEB;
}

.x-btn {
  font-weight: 900;
  font-size: 10px;
  cursor: pointer;
  margin-right: 2px;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-right: 6px;
  width: 74px;
  margin-bottom: -5px;
}

.delayed_status > div span {
  color: red;
}

.change-request__modal-text {
  margin: 8px 12px;
  color: #449996;
  font-size: 20px;
}

.oneChangeRequest {
  display: flex;
  width: 100%;
  color: #449996;
  font-size: 14px;
}

.change-request__columns-2 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 12px 0;
}

.change-request__columns-1 {
  width: 60%;
  padding: 12px 0 12px 12px;
}

.oneChangeRequest-data {
  margin-bottom: 4px;
}

.change-request-accept__text {
  font-size: 21px;
  font-weight: bold;
  color: #449996;
  margin: 24px;
}

.change-request-accept__buttons-container {
    display: flex;
    font-size: 14px;
    place-content: center;
}

.change-request__btn-cancel {
  color: #29CABA;
  border: 1px solid #29CABA;
  background-color: white;
  padding: 4px 20px;
  border-radius: 8px;
  margin: 16px;
  cursor: pointer;
}

.change-request__btn-accept {
  background-color: #29CABA;
  color: white;
  padding: 4px 20px;
  border-radius: 8px;
  margin: 16px;
  cursor: pointer;
}

.customer-price__radioGroup {
  margin: 10px;
}

.customer-price__radioGroup .ant-radio-checked .ant-radio-inner {
  background: #029b96;
}

.no-template-selected-text {
  color: #101C1C;
  font-size: 26px;
  margin: 12px;
}

.customer-price__add-price-btn {
  color: white;
  background: #449996;
  display: flex;
  margin: 4px 10px 12px 8px;
  padding: 4px 12px;
  border-radius: 10px;
  cursor: pointer;
}

.spreadsheet-number-input {
  background-color: white!important;
  box-shadow: 0 0 0 0!important;
  text-align: center!important;
  cursor: pointer!important;
}

.spreadsheet-number-input ::placeholder {
  opacity: 0.5;
}

.dublicate ::placeholder {
  color: red;
}

.dublicate {
  color: red;
}

.spreadsheet-number-input input[type=number] {
  background-color: white!important;
  box-shadow: 0 0 0 0!important;
  text-align: center!important;
  cursor: pointer!important;
}

.spreadsheet-number-input__max input[type=number] {
  background-color: #DDC1C1!important;
}

.price-select > .ant-select-selector {
  background-color: white!important;
  box-shadow: none!important;
}

.price-buttons__container {
  display: flex;
  margin: 18px 12px;
}

.price-button {
  display: flex;
  background-color:  #449996;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 12px;
  padding: 4px 12px;
}

.price-button__cancel {
  background-color: white;
  color:   #449996;
  border: 1px solid  #449996;
}

.price-button-disabled {
  background-color: lightgray;
  border: 1px solid lightgray;
  color: black;
  cursor: not-allowed;
}

.price_modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-modal-button-confirm {
  background-color:  #449996;
  color: white;
  font-weight: 500;
  padding: 4px 23px;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 12px;
  font-size: 16px;
}

.price-modal-button-cancel {
  background-color: white;
  color:  #449996;
  border: 1px solid  #449996;
  font-weight: 500;
  padding: 4px 23px;
  cursor: pointer;
  border-radius: 12px;
  font-size: 16px;
}

.price_modal_text {
  margin: 30px 0 24px 0;
  color:  #449996;
  font-size: 18px;
  font-weight: 900;
}

.price_delete_icons {
  margin: 2px;
  cursor: pointer;
}

.priceTypeSwitch {
  margin: 4px 0 4px 16px;
}

.priceTypeSwitch .ant-switch {
  background-color: #449996!important;
}

.perpallet-text {
  margin: 10px 0 4px 16px;
}

.gross-text {
  margin: 10px 0 4px 200px;
}

.price-placeholder {
  position: absolute;
  z-index: 5;
  padding-left: 24px;
  font-weight: bold;
}