.rc-color-picker-panel-params input{display: inherit; height: inherit; line-height: normal; background: inherit; box-shadow: inherit; flex: inherit; width: inherit; overflow: inherit; white-space: inherit; box-sizing: inherit; text-overflow: inherit;}
.rc-color-picker-panel-wrap{/*height:auto!important;*/}
.rc-color-picker-panel-wrap.rc-color-picker-panel-wrap-has-alpha { /*height: 30px!important;*/}

.rc-color-picker-panel-params-input {display: flex;}
input.rc-color-picker-panel-params-hex {flex: 1;}
label.rc-color-picker-panel-params-lable-hex {flex: 1;}



.color-picker {margin: 4px;}
.color-picker .rc-color-picker-wrap {display: flex;}
.rc-color-picker-wrap  .rc-color-picker-trigger {border: none; display: flex; width: 20px; height: 20px; border-radius: 50%; box-shadow: inset 0 0 0 2px var(--outline);padding:inherit;}
