:root { --space:5px;}



body,html{/*line-height:initial;*/ }
a,a:hover{color:initial}
ul{margin:initial}

h3 {cursor: default;flex: 1 100%; font-weight: 700; margin: 0 5px 5px;font-size:inherit;color:inherit;}

/**/

.ant-tooltip { max-width: 500px }
.ant-tooltip-arrow{display:none}

.ant-select {/*color: rgba(0, 0, 0, 0.65);*/ width:100%; max-width: inherit; font-size:inherit;}
.data_cell > wrap .ant-select{max-width:100%;}

.data_cell > wrap .ant-select-multiple .ant-select-selector {padding-right:5px;}

.ant-select-selector { background-color: inherit!important }

.ant-picker { width: 100%; font-size: inherit; padding: 0; display: flex; border-radius: 0; float: left; display: flex; height: 30px; line-height: 30px; padding: 0px 7px; background: var(--input-color); transition: none; -webkit-transition: none; flex: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; color: rgba(0, 0, 0, 0.65); border: none; box-shadow: 0 0 0 1px var(--outline); }
.ant-input-number { width: 100%;font-size:inherit;padding:0;display:flex;border-radius: 0; float: left; display: flex; height: 30px; line-height: 30px; padding:0px 7px; background: var(--input-color); transition: none; -webkit-transition: none;  flex: 1; overflow: hidden; white-space: nowrap;  text-overflow: ellipsis; color: rgba(0, 0, 0, 0.65);border:none;    box-shadow: 0 0 0 1px var(--outline);}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {height: 30px; padding: 0 7px;border-radius:0;border:none; background: var(--input-color);    box-shadow: 0 0 0 1px var(--outline);}
.ant-select-multiple .ant-select-selector {padding: 0 0 0 3px;  background: var(--input-color);border:none!important; box-shadow: 0 0 0 1px var(--outline); /*border-radius:0!important;*/display:flex;align-items:center;}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {border-color: rgba(0, 0, 0, 0.1);border:none;background: var(--input-color); box-shadow: 0 0 0 1px var(--outline);}


.input_cont .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { background: none; border: none;}

.ant-selector:hover, .ant-picker:hover{box-shadow: 0 0 0 1px var(--outline)!important;border:none;}
.ant-input-number:focus, .ant-input-number-focused{box-shadow: 0 0 0 1px var(--outline)!important;border:none;background: none;}


.ant-select .ant-select-selector,
.ant-picker .ant-picker-input,
.ant-input-number .ant-input-number-input-wrap { padding: 0; border-radius: 0; height: 100%; }
.ant-picker-range { background-color: #f3f3f3;}


.ant-input-number-handler-wrap {/*display:none;*/}


.ant-selector:hover,
.ant-picker:hover {border-color: rgba(0, 0, 0, 0.1);box-shadow:none;}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-focused.ant-select-multiple .ant-select-selector,
.ant-picker-focused,
.ant-input-number-focused {border-color: rgba(0, 0, 0, 0.1);box-shadow:none;background:none!important;border:none!important;}



.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector  {box-shadow: 0 0 0 1px var(--outline);border:none;background:none;}


.ant-input-number-input { padding: 0; border-radius: 0;color:inherit;height:100%;}
.ant-input-number-input-wrap { width: 100%;}

.ant-input-number:hover,
.ant-input-number-focused{ box-shadow: 0 0 0 1px var(--outline);}
.ant-input-number-input::-webkit-input-placeholder {color:inherit;}



.ant-select-single{}
    .ant-select-single .ant-select-selector .ant-select-selection-search { left: 7px; }
    .ant-select-single .ant-select-selector .ant-select-selection-search input{ height:100%!important; } 


.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker.ant-picker-focused,
.form_fields.line > .ant-picker.ant-picker-focused,
.form_fields.line > .ant-input-number:focus{box-shadow:inset 0 0 0 1px var(--outline); background: inherit;}




.ant-picker-input > input { font-size: inherit; border-radius: 0; height: 100%; }








.ant-select-multiple { }
.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder{left: 7px;}
.ant-select-multiple .ant-select-selector{padding-left:3px;background:var(--input-color)!important;border-radius:0!important;}

.ant-select-multiple .ant-select-selection-item{background: #029b96; border-color:#029b96;color:#fff;border-radius:0;}
.ant-select-multiple .ant-select-selection-item-remove { vertical-align: 0; font-size: inherit; line-height: 100%; display: flex; align-items: center; justify-content: center;height:100%; }
.ant-select-multiple .ant-select-selection-item-remove,
.ant-select-multiple .ant-select-selection-item-remove:hover{color:inherit;}

.ant-select-multiple.ant-select-open .ant-select-selector {background: transparent;}

.ant-select-multiple .ant-select-selection-item-content { display: flex;align-items: center;justify-content:center; font-size: inherit; line-height: 100%;height:100%; }



.ant-select-multiple .ant-select-selection-search { line-height: 100%;margin:0; }
.ant-select-multiple .ant-select-selection-search input { line-height: 100%; height: 100%; }





    .form_fields > .ant-time-picker, .form_fields > .ant-input-number, .form_fields > .ant-calendar-picker, .form_fields > .ant-select, .form_fields > .ant-picker { margin: 5px; flex-grow: 1; }

.ant-picker-clear{ background:#f3f3f3;}
.ant-picker-focused .ant-picker-clear{ background:#fff;}





.input-validation-error.ant-select:not(.ant-select-borderless),
.input-validation-error.ant-input-number,
.input-validation-error.ant-picker{ border:none; box-shadow: 0 0 0 2px rgba(240, 80, 80, 0.3)!important;}

.input-validation-warning.ant-select:not(.ant-select-borderless),
.input-validation-warning.ant-input-number,
.input-validation-warning.ant-picker{ border:none; box-shadow: 0 0 0 2px rgba(251, 203, 0, 0.3)!important;}


.input-validation-error.ant-select:not(.ant-select-borderless) .ant-select-selector,
.input-validation-error.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,

.input-validation-error.ant-input-number,
.input-validation-error.ant-picker{ border:none;}

.ant-select-single.input-validation-error:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple.input-validation-error .ant-select-selector{box-shadow:none;}


/*costom table_view*/
.table_view wrap .ant-picker,
.table_view wrap .ant-select,
.table_view wrap .ant-select .ant-select-selector{box-shadow:none; background:transparent;flex:1;height:30px;line-height:30px;}

.table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-item,
.table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height:30px;}

.table_view wrap .ant-select:not(.ant-select-disabled):hover .ant-select-selector{box-shadow:inset 0 0 0 1px var(--outline);}


.table_view wrap .ant-picker:hover,
.table_view wrap .ant-select:hover,
.table_view wrap .ant-selector:hover,
.table_view wrap .ant-select:hover .ant-select-selector,

.table_view wrap .ant-select-focused.ant-picker,
.table_view wrap .ant-select-focused.ant-select .ant-select-selector{box-shadow:none!important; background: transparent;}


.table_view wrap .ant-picker.input-validation-error,
.table_view wrap .ant-select.input-validation-error {border: none; box-shadow: inset 0 0 0 2px rgba(240, 80, 80, 0.3)!important;}

.table_view wrap .ant-picker.input-validation-warning,
.table_view wrap .ant-select.input-validation-warning {border: none; box-shadow: inset 0 0 0 2px rgba(253, 203, 0, 0.3)!important;}



/*table*/

.ant-table-thead > tr > th { font-weight: bold; }
.ant-table.ant-table-small{font-size:inherit}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td { padding: 0px 5px; height: 30px; }

.ant-table.ant-table-small .ant-table-thead > tr > th { background-color: #929292; color: #fff; }
.ant-table.ant-table-small .ant-table-thead > tr > th:hover { background-color: #a1a1a1; color: #fff; }
.ant-table-column-sorter, .ant-table-column-sorters:hover .ant-table-column-sorter { color: inherit; }

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters { padding: 0px 5px; }

.ant-table-cell.ant-table-row-expand-icon-cell { align-items: center; justify-content: center;}



.ant-table-wrapper{height:100%}
.ant-spin-nested-loading{height:100%}
.ant-spin-container { display:flex; flex-direction:column; height:100%; }
.ant-table.ant-table-small{display:flex;flex-direction:column; flex-grow: 1;}
.ant-table-container { display: flex; flex-direction: column; height: 100%; }
.ant-table-pagination.ant-pagination { flex-shrink: 0; margin: 12px 0; z-index: 1000;}

.ant-table-header {flex-shrink: 0;}
.ant-table-expand-icon-col {width:40px;}


td .ant-selector:hover,td .ant-picker:hover{box-shadow:none!important;}

div.section-table {max-width:1000px}

div.section-table .ant-table table {
    width: inherit !important
}

/*.ant-table{background:none;}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{background: var(--highlight);}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on{color: var(--main-color);}

.ant-table,.ant-table-thead > tr > th{color:inherit;}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{padding:0 0px;border:none;transition:none;background:none!important;}
.ant-table-thead > tr > th{padding:0 0px;font-weight: 600;}

.ant-table-tbody > tr > td.ant-table-row-cell-break-word{padding:0;}

.ant-table-tbody > tr .ant-table{margin:0 !important;}
tr.ant-table-expanded-row > td{padding:0;}
tr.ant-table-expanded-row:hover > td{}

.ant-table-cell .ant-table-thead tr{box-shadow:none;}

.ant-table-cell .ant-table-thead th{}
.ant-table-expand-icon-col {width:40px;}
.ant-table-row-expand-icon{   margin: 0 auto; float: none;}
.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover{color: var(--main-color);}





.ant-table-wrapper {height: 100%;}
.ant-spin-nested-loading {height: 100%;}
.ant-spin-container {height: 100%;display: flex; flex-direction: column;}
.ant-table { max-height: 100%; overflow: hidden;}
.ant-table-container {height: 100%;display: flex; flex-direction: column;overflow: hidden;}
.ant-table-header {flex-shrink: 0;}
.ant-table-body {flex-grow: 1;max-height:100%!important;overflow: auto;overflow-y: auto!important; overflow-x: hidden;}

.ant-table-column-sorters{padding:0 10px;}*/
/*pagination*/
/*.ant-pagination{flex-shrink: 0; margin: 10px 0; display: flex; justify-content: flex-end;}
.ant-pagination-item{border-radius:0;}
.ant-pagination .ant-select-selector{background:inherit!important;}



.ant-pagination-item,
.ant-pagination-prev, 
.ant-pagination-next, 
.ant-pagination-jump-prev, 
.ant-pagination-jump-next{ margin-right:5px; font-family: inherit;border-radius:0!important;}

.ant-pagination-item a,
.ant-pagination-prev a,
.ant-pagination-next a, 
.ant-pagination-jump-prev a, 
.ant-pagination-jump-next a{line-height: 30px;min-width:30px;border-radius:0!important;}
.ant-pagination .ant-select{margin-right:0;}*/
/**/
.ant-form-item { margin: 0; }





/*custom*/
.ant-picker-panel-container.custom { display: flex; flex-wrap: wrap; }

.ant-picker-panel-container.custom holder { display: flex; }
.ant-picker-panel-container.custom holder[horizontal] { flex-direction: column; }

.ant-picker-dropdown .form_container { background: rgb(0 0 0 / 1%); margin: 5px; }
.ant-picker-dropdown { z-index: 0 }


/*dropdown*/
.ant-select-dropdown-menu { max-height: 250px; margin-bottom: 0; padding:5px 0; padding-left: 0; overflow: auto; list-style: none; outline: none;}

.list_menu.ant-dropdown-menu { padding: 0; border-radius: 10px; margin: 0;box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); box-shadow: var(--elevation);}

/**/




/*uploader*/
.avatar-uploader {}
.ant-upload-picture-card-wrapper { zoom: 1; display: inline-block; width:auto; margin: 5px;   flex-grow: 0;}
.ant-upload-picture-card-wrapper::before, .ant-upload-picture-card-wrapper::after{display:none;}

.ant-upload.ant-upload-select-picture-card {display: flex; margin: 0; border: none;  width: 80px; height: 80px;   border: none;  box-shadow: 0 0 0 1px var(--outline); padding: 5px; background: var(--input-color);border-radius:0;flex-shrink:0;}
.ant-upload.ant-upload-select-picture-card:active i.anticon{transform:translateY(1px);}

.ant-upload{ color: inherit;}
.ant-upload.ant-upload-select-picture-card > .ant-upload { display: flex;  align-items: center; justify-content: center; width: 100%; height: 100%; padding: 0; color: rgba(0, 0, 0, 0.25);overflow:hidden;position:relative;}

.ant-upload img{/*height:100%;width: auto!important;*/display: block; max-height: 160px; max-width: 160px; position: absolute; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%; min-width: 80px; min-height: 80px;}
.ant-upload input[type='file'] {}
.ant-upload-text{display:none;}
.ant-upload i.anticon{font-size: 20px;display: flex; align-items: center; justify-content: center; color: inherit;}

.ant-upload.ant-upload-select.ant-upload-select-picture-card { /*background: none;*/position:relative;}

.ant-upload.ant-upload-select-picture-card > .ant-upload .button{position: absolute; background: rgb(255 255 255 / 0.6); color: rgb(0 0 0 / 0.6);}
.ant-upload.ant-upload-select-picture-card > .ant-upload .button:hover {background: rgb(255 255 255 / 0.8); color: rgb(0 0 0 / 0.8);}


/*.form_fields.multiline .ant-upload-picture-card-wrapper { width: auto; flex-grow: 0;}*/

.avatar-uploader { position: relative;  width: 80px; height: 80px; display: flex; align-items: center; justify-content: center; flex-shrink: 0; flex-grow: 0; margin: 5px; padding: 5px; /*box-shadow: inset 0 0 0 1px var(--outline); background: var(--input-color);*/}
.avatar-uploader > span {display: flex; position: relative; height: 100%; width: 100%; overflow: hidden;}
.avatar-uploader img{display: block; max-height: 160px; max-width: 160px; position: absolute; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%; min-width: 80px; min-height: 80px;}
.avatar-uploader .button{position: absolute; background: rgb(255 255 255 / 0.6);  color: rgb(0 0 0 / 0.6);}
.avatar-uploader .button:hover{background: rgb(255 255 255 / 0.8);  color: rgb(0 0 0 / 0.8);}

/**/


/*uploader*/

[schemas] nav .ant-upload.ant-upload-select-picture-card { /*box-shadow: 0 0 0 1px;*/    background-color: var(--main-color);}
[schemas] nav .ant-upload.ant-upload-select-picture-card > .ant-upload { /*color: rgb(0, 0, 0);*/color: rgb(255, 255, 255);}

/**/








/*tree*/
.ant-tree-list{ color:inherit; font-size: 13px; line-height:inherit; margin: 0; padding:20px 0 20px 10px;}
.ant-tree .ant-tree-treenode{align-items:center;}
.ant-tree .ant-tree-node-content-wrapper{min-height:30px;line-height:30px;display:flex;align-items:center;padding:0 10px;border-radius:0;}

/**/






/*piceker*/
.ant-time-picker  .ant-time-picker-input { background: transparent;  width: 100%;  padding: 0; border-radius: 0; border: none; box-shadow: none; font-size: var(--text);}
.ant-time-picker-clear { background: rgb(243, 243, 243);}


.ant-calendar-picker-input.ant-input { width: 100%; line-height: 30px; background: transparent;font-size: var(--text);}
.ant-calendar-picker > div { width: 100%;position:relative;}
.ant-calendar-picker-clear  { background: rgb(243, 243, 243);}


/*.ant-select-selection--multiple { min-height: 30px; height: auto; padding:0px;}
.ant-select-selection__rendered { line-height: normal;}
.ant-select-selection__choice {}
.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li{line-height: 30px;height:30px;margin:0px;}
.ant-select-selection--multiple .ant-select-selection__choice {box-shadow: 0 0 0 1px var(--outline);border: none;background-color: var(--main-color);color: #fff;max-width: 100%;display: flex;justify-content: space-between;align-items: center;position: relative;margin: 0 2px 0 0;padding: 0 5px 0 10px;min-width: 60px;border-radius: 0;}

.ant-select-selection__rendered { display: flex; align-items: center;}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove{font-size: 14px;}
.ant-select-selection--multiple .ant-select-selection__choice__remove{position: relative; display: flex; align-items: center; justify-content: center; right: auto;}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg{color:#fff;}*/






.ant-time-picker-panel-select li { width: 100%; height: 40px; margin: 0; padding: 0 0 0 12px; line-height: 40px; text-align: left;color: initial;}
.ant-time-picker-panel-select li:hover,
.ant-time-picker-panel-select li:focus { background: var(--highlight);}

.ant-time-picker-panel-select li:focus {color:initial; font-weight: normal;}

li.ant-time-picker-panel-select-option-selected,
li.ant-time-picker-panel-select-option-selected:hover,
li.ant-time-picker-panel-select-option-selected:focus{ font-weight: normal; color: #fff; background: var(--main-color);}




.ant-calendar-picker-clear, .ant-calendar-picker-icon { top: 95%; transform: translateY(-100%); right:0px;  z-index: 1; width: 15px; height: 15px;font-size: 15px;position:absolute;}
.ant-calendar-picker-icon { display: inline-block; color: rgba(0, 0, 0, 0.25); font-size: 15px; line-height: 1;}
.ant-calendar-picker-clear { z-index: 2; color: rgba(0, 0, 0, 0.25); font-size: 15px; background:rgb(243, 243, 243); cursor: pointer; pointer-events: none;opacity:0;}
.ant-calendar-picker:hover .ant-calendar-picker-clear {opacity:1; pointer-events: auto;}
.ant-calendar-picker-clear svg, .ant-calendar-picker-icon svg {position: absolute;}
/**/

/*calendar*/

.ant-calendar {width: 300px; font-size: 13px; line-height: normal; text-align: left; border: none; border-radius: 0; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);}
.ant-calendar-input-wrap { height: 40px; padding:5px 10px;border-bottom: none;display:flex;}
.ant-calendar-date-input-wrap{flex-grow:1;}
.ant-calendar-input {width: 100%; height:30px; color: rgba(0, 0, 0, 0.65);}
.ant-calendar-input-wrap > a{/*width:30px;*/}

.ant-calendar .ant-calendar-clear-btn{position:static;}

.ant-calendar-header {height:auto;line-height: 40px; text-align: center;border:none; padding: 5px 10px; box-shadow: 0 -1px 0 0 var(--border-color),0 1px 0 0 var(--border-color);}
.ant-calendar-header > div{display:flex; align-items: center; justify-content: center;}

.ant-calendar-header > .ant-calendar-decade-panel,
.ant-calendar-header > .ant-calendar-month-panel,
.ant-calendar-header > .ant-calendar-year-panel {align-items: inherit; justify-content: inherit;  height: auto;  border-radius: 0;}

.ant-calendar-decade-panel{/*position:static;display:flex;justify-content:center;flex-direction:row;*/}



.ant-calendar-decade-panel-header,
.ant-calendar-month-panel-header,
.ant-calendar-year-panel-header {    padding: 5px 10px;height: 40px;line-height: 40px; text-align: center; border: none;display:flex;width:100%; align-items: center;box-shadow: 0 -1px 0 0 var(--border-color),0 1px 0 0 var(--border-color);}




.ant-calendar-decade-panel-century,

.ant-calendar-header .ant-calendar-century-select, 
.ant-calendar-header .ant-calendar-decade-select, 
.ant-calendar-header .ant-calendar-year-select, 
.ant-calendar-header .ant-calendar-month-select,

.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select, 

.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select{ position: static; top: 0; display: flex; align-items:center;justify-content:center;padding: 0; color: rgba(0, 0, 0, 0.45); font-size: inherit; font-family: inherit; line-height: 30px; height:30px; min-width:30px; padding: 0 5px;flex-shrink: 0;margin:auto;cursor:pointer;}

.ant-calendar-decade-panel-century{cursor:default;}



.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,

.ant-calendar-header .ant-calendar-prev-century-btn, 
.ant-calendar-header .ant-calendar-next-century-btn, 
.ant-calendar-header .ant-calendar-prev-decade-btn, 
.ant-calendar-header .ant-calendar-next-decade-btn, 
.ant-calendar-header .ant-calendar-prev-month-btn, 
.ant-calendar-header .ant-calendar-next-month-btn, 
.ant-calendar-header .ant-calendar-prev-year-btn, 
.ant-calendar-header .ant-calendar-next-year-btn,

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn{ position: static; top: 0; display: flex; align-items:center;justify-content:center;padding: 0; color: rgba(0, 0, 0, 0.45); font-size: inherit; font-family: inherit; line-height: 30px; width:30px;height:30px;flex-shrink: 0;cursor:pointer;}



.ant-calendar-my-select {flex-grow: 1;display: flex; justify-content: center;}
.ant-calendar-header .ant-calendar-my-select > a{flex-grow:0;margin:0;}



.ant-calendar-column-header { width:30px; padding:0; line-height:30px; text-align: center;}



.ant-calendar-header .ant-calendar-next-month-btn::before { top: -1px; display:block; width: 6px; height: 6px; border-width: 2px 0 0 2px; border-radius: 1px;}
.ant-calendar-header .ant-calendar-prev-month-btn::before { top: -1px; display:block; width: 6px; height: 6px; border-width: 2px 0 0 2px; border-radius: 1px;}



.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after,

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after,

.ant-calendar-header .ant-calendar-next-century-btn::before, 
.ant-calendar-header .ant-calendar-next-decade-btn::before, 
.ant-calendar-header .ant-calendar-next-year-btn::before, 
.ant-calendar-header .ant-calendar-next-century-btn::after, 
.ant-calendar-header .ant-calendar-next-decade-btn::after, 
.ant-calendar-header .ant-calendar-next-year-btn::after,

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {top: -1px; left: -2px; display:block; width: 6px; height: 6px;border-width: 2px 0 0 2px;}




.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,

.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,

.ant-calendar-header .ant-calendar-next-century-btn::before, 
.ant-calendar-header .ant-calendar-next-decade-btn::before, 
.ant-calendar-header .ant-calendar-next-year-btn::before,

.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {left: 2px;}







.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after,

.ant-calendar-header .ant-calendar-prev-century-btn::before, 
.ant-calendar-header .ant-calendar-prev-decade-btn::before, 
.ant-calendar-header .ant-calendar-prev-year-btn::before, 
.ant-calendar-header .ant-calendar-prev-century-btn::after, 
.ant-calendar-header .ant-calendar-prev-decade-btn::after, 
.ant-calendar-header .ant-calendar-prev-year-btn::after,

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after,

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after{ top: -1px; left:3px; display:block; width: 6px; height: 6px; border-width: 2px 0 0 2px;}







.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after, 
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after,

.ant-calendar-header .ant-calendar-prev-century-btn::after, 
.ant-calendar-header .ant-calendar-prev-decade-btn::after, 
.ant-calendar-header .ant-calendar-prev-year-btn::after,

.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after, 
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after,

.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after, 
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after { left:-1px;}










.ant-calendar-body{padding:15px 0 10px 0;}
.ant-calendar-cell { height: 40px; padding:0;}


.ant-calendar-date,
.ant-calendar-month-panel-month,
.ant-calendar-year-panel-year,
.ant-calendar-decade-panel-decade { display: block;  min-width: 40px; height: 40px; line-height: 40px;  margin: 0 auto; padding: 0; color: initial; font-weight: 600; text-align: center;background: transparent; border:none; border-radius: 0px;}

.ant-calendar-today .ant-calendar-date {font-weight: bold; box-shadow: 0 0 0 1px var(--main-color);color: var(--main-color);}


.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade{ background: var(--main-color); color: #fff;}



.ant-calendar-date:hover,
.ant-calendar-month-panel-month:hover,
.ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-decade:hover { background: var(--highlight);color:inherit;}


.ant-calendar-date:active,
.ant-calendar-month-panel-month:active,
.ant-calendar-year-panel-year:active,
.ant-calendar-decade-panel-decade:active  { color: #fff;  background: var(--main-color);}



.ant-calendar-selected-day .ant-calendar-date:hover,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover { color: #fff;  background: var(--main-color);}



.ant-calendar-date:hover:active,
.ant-calendar-month-panel-month:hover:active,
 .ant-calendar-year-panel-year:hover:active,
.ant-calendar-decade-panel-decade:hover:active { color: #fff;  background: var(--main-color);}




.ant-calendar-header a:hover{color:initial;}
.ant-calendar-header .ant-calendar-my-select a:hover,
.ant-calendar-header .ant-calendar-my-select a:hover {color: var(--main-color);}
/**/



/*checkbox*/

.ant-checkbox-wrapper{}
.ant-checkbox{}

.ant-checkbox-indeterminate .ant-checkbox-inner{ border-color: #029b96;}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{    background-color: #029b96;}

.ant-checkbox-inner{}
.ant-checkbox-inner::after{    top: 47%;    left: 27%;    width: 3.714286px;    height: 6.142857px;}

.ant-checkbox-checked .ant-checkbox-inner{background-color: #029b96; border-color: #029b96;}
.ant-checkbox-checked .ant-checkbox-inner::after{}

.create-group__checkbox .ant-checkbox {
  padding-left: 4px;
}

.customer-order-admin__checkbox .ant-checkbox-checked .ant-checkbox-inner{background-color: #29CABA; border-color: #29CABA;}

.ant-checkbox-checked::after{border: none;}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner {border-color: #029b96;}

.customer-order-admin__checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {border-color: #29CABA;}


tr.drop-over-downward td {
    border-bottom: 2px dashed #029b96;
}

tr.drop-over-upward td {
    border-top: 2px dashed #029b96;
}


/*preloader*/
view[small][preloader] > .ant-spin-nested-loading {display: block; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%;opacity: 1;z-index: 2;position:absolute;height:100%;width:100%;}
.toggler > .ant-spin.ant-spin-spinning {position: relative; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;}


/*spinner*/
/*.ant-table-cell spinner {}
.ant-table-cell spinner .ant-spin-spinning {}
.ant-table-cell spinner .ant-spin-dot {font-size: 23px;}
.ant-table-cell spinner .ant-spin-lg .ant-spin-dot i {width: 10px; height: 10px;}*/





/*ant-collapse*/
.ant-collapse {width: 100%; box-shadow: 0 0 0 1px var(--outline);border: none; line-height: inherit; background-color: inherit; border-radius: inherit; font-size: inherit; color: inherit;}
.ant-collapse > .ant-collapse-item{border-bottom:none;}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {padding:0 0 0 40px; height: 50px;line-height:50px; background: var(--input-color);}




/*color override*/
.ant-table-cell wrap input,
.ant-table-cell wrap .ant-select,
.ant-table-cell wrap .ant-select-selector
.ant-table-cell wrap .ant-select-selection-item,

.ant-table-cell wrap .ant-picker,
.ant-table-cell wrap .ant-picker-input,
.ant-table-cell wrap .ant-picker-input > input,



.table_view wrap input,
.table_view wrap .ant-select,
.table_view wrap .ant-select-selector
.table_view wrap .ant-select-selection-item,

.table_view wrap .ant-picker,
.table_view wrap .ant-picker-input,
.table_view wrap .ant-picker-input > input {color:inherit}

/*table item selected*/
table td > wrap > input:focus,
table td > wrap > .ant-picker.ant-picker-focused,
table td > wrap > .ant-picker.ant-picker-focused:hover,
table td > wrap > .ant-select.ant-select-focused,
table td > wrap > .ant-select.ant-select-focused:hover {box-shadow:inset 0 0 0 1px var(--focused)!important; transition:box-shadow .125s;}




/*colorize*/
.table_view wrap .ant-picker.uniform .ant-picker-input > input { color: rgb(86, 193, 255)!important; text-align: end!important;}
.table_view wrap .ant-picker.standard .ant-picker-input > input { color: blue!important; text-align: end!important;}
.table_view wrap .ant-picker.date-change .ant-picker-input > input { color: #EF2112!important; text-align: end!important;}
.table_view wrap .ant-picker.hard-date .ant-picker-input > input { color: rgb(96, 0, 128)!important; text-align: end!important;}
.table_view wrap .ant-picker.centerText .ant-picker-input > input { text-align: end!important;}
.table_view wrap .ant-picker.red .ant-picker-input > input { color: #EF2112!important; text-align: end!important;}
/**/


.ant-dropdown-trigger { display: flex; align-items: center; }

/**/
.ant-table-cell-fix-right{}
.ant-table-has-fix-right > th { z-index: 999; position: relative; background: #fafafa; }
.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first { position: relative; z-index:1000; }




/*Tabs*/

.ant-tabs-top > .ant-tabs-nav { margin: 0 0 0 10px; }
.ant-tabs-content { height: 100%; }





/*SIZES*/

[compact_view] .ant-select-single .ant-select-selector .ant-select-selection-item,
[compact_view] .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
[compact_view] .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
[compact_view] .ant-select-selector,
[compact_view] .ant-picker,
[compact_view] .ant-input-number { height: var(--height); line-height: var(--height); }


[compact_view] .ant-table.ant-table-small .ant-table-title,
[compact_view] .ant-table.ant-table-small .ant-table-footer,
[compact_view] .ant-table.ant-table-small .ant-table-thead > tr > th,
[compact_view] .ant-table.ant-table-small .ant-table-tbody > tr > td,
[compact_view] .ant-table.ant-table-small tfoot > tr > th,
[compact_view] .ant-table.ant-table-small tfoot > tr > td { height: var(--height); }

[compact_view] .ant-dropdown{font-size:inherit;}
[compact_view] .ant-table-column-sorter-up,
[compact_view] .ant-table-column-sorter-down { font-size: var(--text_s); }


[compact_view] .ant-select-multiple { min-height: var(--height); }
[compact_view] .ant-select-multiple .ant-select-selector { display: flex; /*align-items: flex-start;*/ height: auto; line-height: var(--height); padding-left: 7px; min-height: var(--height); }
[compact_view] .ant-select-multiple .ant-select-selection-overflow { }
[compact_view] .ant-select-multiple .ant-select-selection-overflow-item { height: var(--item_line_height); margin: var(--item_margin); }
[compact_view] .ant-select-multiple .ant-select-selection-item { height: var(--item_height); line-height: var(--item_line_height); margin-top: 0px; margin-bottom: 0px; margin-inline-end: 0px; padding-inline-start: 6px; padding-inline-end: 3px; border: none; }



[compact_view] .ant-select-multiple .ant-select-selector::after { height: var(--item_height); line-height: var(--item_height); display: none; }

[compact_view] .ant-input-number .anticon,
[compact_view] .ant-picker-clear,
[compact_view] .ant-select-arrow,
[compact_view] .ant-picker-suffix,
[compact_view] .ant-select-clear { font-size: var(--text); color: #000; margin-left: 0; padding-right: 0; right: 5px; position: absolute; }


[compact_view] .ant-time-picker,
[compact_view] .ant-input-number,
[compact_view] .ant-calendar-picker,
[compact_view] .ant-select,
[compact_view] .ant-picker { padding: 0; }

[compact_view] .ant-select-single .ant-select-selector .ant-select-selection-item,
[compact_view] .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
[compact_view] .ant-picker-input { right: 0; left: 0; padding-right: 20px; }



[compact_view] .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { padding-right: 0; }

[compact_view] .ant-select-selector,
[compact_view] .ant-picker,
[compact_view] .ant-input-number { padding: 0px 0px 0 7px; }





[compact_view] .table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-item,
[compact_view] .table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-placeholder { line-height: var(--height); }




/*form_fields line */

[compact_view] .form_fields.line > input { padding-right: 7px;padding-left:7px; }
[compact_view] .form_fields.line.TBD > input { padding-right: 7px; padding-left: 7px; margin: 0 4px 0 8px }

[compact_view] .form_fields.line .ant-input-number .anticon,
[compact_view] .form_fields.line .ant-picker-clear,
[compact_view] .form_fields.line .ant-select-arrow,
[compact_view] .form_fields.line .ant-picker-suffix,
[compact_view] .form_fields.line .ant-select-clear { right: 10px; }

[compact_view] .form_fields.line .ant-select-single .ant-select-selector .ant-select-selection-item,
[compact_view] .form_fields.line .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
[compact_view] .form_fields.line .ant-picker-input {  padding-right:30px; }


/* custom checkbox */

.container {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

  /* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

  /* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 3px;
    left: 12px;
    height: 13px;
    width: 13px;
    background-color: #DEDEDE;
    border-radius: 10px;
}

.checkmark-one {
  left: 26px;
}

  /* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

  /* When the priority checkbox is checked, add a red background */
  .container input:checked ~ .checkmark.priority {
    background-color: #FF0000;
}

  /* When the delayed checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark.delayed {
    background-color: #00A3FF;
}

  /* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: block;
}

  /* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid #FFFFFF;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

  /* custom checkbox */

  .ant-popover .pod-status-modal__container .ant-table-wrapper {
    width: 100%;
};

.ant-popover .pod-status-modal__table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.ant-popover.pod-status-modal__table .comment-modal__th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
    text-align: center;
}

.ant-popover .pod-status-modal__table .comment-modal__td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
    text-align: center;
}

.ant-popover .pod-status-modal__table .comment-modal__td:nth-child(1) {
    min-width: 100px;
}

.ant-popover .pod-status-modal__table .comment-modal__td:nth-child(2) {
    min-width: 120px;
}

.ant-popover .pod-status-modal__table .comment-modal__td:nth-child(3) {
    text-align: left;
}

.ant-popover-content .pod-status-modal__table {
  width: 300px;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #51B2AD;
  border-color: #51B2AD;
}